<script setup>
    import Actions from 'o365.pwa.vue.components.navbar.offCanvas.Actions.vue';
    import AppStatus from 'o365.pwa.vue.components.navbar.offCanvas.AppStatus.vue';

    const props = defineProps(['pwaState', 'isAccordionOpen']);
</script>

<template>
    <Transition appear>
        <div v-if="isAccordionOpen">
            <AppStatus :pwaState="pwaState"></AppStatus>
                        
            <Actions>
                <template #syncActions>
                    <slot name="syncActions"></slot>
                </template>
            </Actions>
        </div>
    </Transition>
</template>